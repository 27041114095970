import React, { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import styled from '@lib/styled';
import { Space, Typography, Divider, Anchor, Tag, Row, Col } from 'antd';
import Button from '@components/atoms/Button';
import Logo from '@icons/Logo';
import firebase from '../../lib/firebase';
import brand from '@lib/brand';
import { primaryColor } from '@lib/theme';
import LogoStack from '@atoms/LogoStack';
const { Text, Title } = Typography;
const { Link } = Anchor;

function Footer({ vpadding = 10 }) {
  const router = useRouter();
  const crumbs = router.asPath.split('/');
  const firstCrumb = crumbs.length > 1 ? crumbs[1] : '';

  return (
    <>
      <Container vpadding={vpadding}>
        <LogoSection>
          <LogoStack />
        </LogoSection>

        <LinkContainer>
          <ItemLinks>
            <LinkTitle strong={true}>
              <a href="/about">Product</a>
            </LinkTitle>
            <List>
              <li>
                <Item href="/tutorial">Tutorial</Item>
              </li>
              <li>
                <Item href="/faq">FAQ</Item>
              </li>
              <li>
                <Item href="/release">Feature Release Schedule</Item>
              </li>
            </List>
          </ItemLinks>

          <ItemLinks>
            <LinkTitle strong={true}>
              <a href="/about">About</a>
            </LinkTitle>
            <List>
              <li>
                <Item href="/faq">FAQ</Item>
              </li>
              <li>
                <Item href="/volunteer">Volunteer</Item>
              </li>
              <li>
                <Item href="/supervision">Supervision</Item>
              </li>
            </List>
          </ItemLinks>

          <ItemLinks>
            <LinkTitle strong={true}>
              <a href="/terms">Legal</a>
            </LinkTitle>
            <List>
              <li>
                <Item href="/privacy">Privacy</Item>
              </li>
              <li>
                <Item href="/terms">Terms of Service</Item>
              </li>
              <li>
                <Item href="/hipaa">HIPAA</Item>
              </li>
            </List>
          </ItemLinks>
        </LinkContainer>
      </Container>
      <div
        style={{
          textAlign: 'right',
          paddingBottom: '20px',
          paddingRight: '4vw'
        }}>
        <Text>Copyright {brand.brand} 2020. All Rights Reserved</Text>
      </div>
    </>
  );
}

const Container = styled.div`
  display: flex;
  z-index: 100;
  padding-top: ${({ vpadding }: any) => vpadding}px;
  padding-left: 4vw;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  flex: 1;
`;

const LinkContainer = styled.div`
  padding: 1rem 0 1rem 1rem;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 810px) {
    max-width: 60vw;
  }
`;

const ItemLinks = styled.div`
  min-width: 180px;
  margin-right: 20px;
`;

const LinkTitle = styled(Text)`
  font-size: large;
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const Item = styled.a`
  color: black;
`;

const EndBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 5px;
  flex: 10vw;
`;
const LogoSection = styled(Space)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 250px;
  padding-left: 2rem;
  margin-bottom: 20px;
`;

const Tagline = styled(Text)`
  margin-top: 5px;
  text-align: end;
  margin-bottom: 5px;
`;

const TagText = styled(Title)`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  flex: 500px;
`;

const CompactTitle = styled(Title)`
  margin-top: 0px;
  margin-bottom: 0px;
`;

export default Footer;
